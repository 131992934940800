// :cow: Cosmose CONFIDENTIAL :iso:
import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { catchError } from 'rxjs/operators';
import { LocalStorageKey } from '@kai/modules/click-and-collect/core/models/localStorageKey';
import { ConfigService } from '@kai/core/config/services/config.service';

@Injectable()
export class ClickAndCollectInterceptor implements HttpInterceptor {

  constructor(
    private store$: Store,
    private configService: ConfigService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const accessToken = localStorage.getItem(LocalStorageKey.installationId) || '';
    return next.handle(this.addAuthHeaders(request, accessToken))
      .pipe(
        catchError((error) => {
          if (error) {
            console.log(error);
          }
          return throwError(error);
        }));
  }

  addAuthHeaders(req: HttpRequest<any>, token: string): any {
    const cond =
      req.url.includes(this.configService.getConfig().dhApiRoot)
      && !req.url.includes('store-assistant-click-and-collect-login')
      && token;

    if (cond) {
      return req.clone({
        setHeaders: {
          'installation-id': `${ token }`,
        },
      });
    }
    return req;
  }
}
