// :cow: Cosmose CONFIDENTIAL :iso:
import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class IconsService {
  icons = [
    {
      name: 'menu',
      fileName: 'Menu.svg',
    },
    {
      name: 'search',
      fileName: 'Search.svg',
    },
    {
      name: 'arrow_left',
      fileName: 'Arrow left.svg',
    },
    {
      name: 'arrow_right',
      fileName: 'Arrow Right.svg',
    },
    {
      name: 'close',
      fileName: 'Close.svg',
    },
    {
      name: 'history',
      fileName: 'History.svg',
    },
    {
      name: 'pause',
      fileName: 'Pause.svg',
    },
    {
      name: 'refresh',
      fileName: 'Refresh.svg',
    },
    {
      name: 'faq',
      fileName: 'FAQ.svg',
    },
    {
      name: 'contact',
      fileName: 'Contact.svg',
    },
    {
      name: 'privacy',
      fileName: 'Privacy.svg',
    },
    {
      name: 'details',
      fileName: 'Details.svg',
    },
    {
      name: 'cart',
      fileName: 'Cart.svg',
    },
  ];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
  }

  registerIcons(): void {
    this.icons.forEach((icon) => {
      this.matIconRegistry.addSvgIcon(
        icon.name,
        this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/click/' + icon.fileName),
      );
    });
  }
}
