// :cow: Cosmose CONFIDENTIAL :iso:
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { IconsService } from '@kai/core/services/icons.service';

@Component({
  selector: 'kai-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private googleTagManagerService: GoogleTagManagerService,
    private iconsService: IconsService,
  ) {
    environment.languages
      .forEach((lang) => {
        translateService.setTranslation(lang.name, lang.value);
      });
    translateService.setDefaultLang(environment.languages[0].name);
  }

  ngOnInit(): void {
    this.router.events
      .forEach((item) => {
        if (item instanceof NavigationEnd) {
          const gtmTag = {
            event: 'page',
            pageName: item.url,
          };
          this.googleTagManagerService.pushTag(gtmTag);
        }
      });

    this.iconsService.registerIcons();
  }
}
