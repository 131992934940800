import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export enum ScheduleStatus {
  UNCOLLECTED = 'UNCOLLECTED',
  TO_ACCEPT = 'TO_ACCEPT',
  TO_PREPARE = 'TO_PREPARE',
  IN_PREPARATION = 'IN_PREPARATION',
  READY_TO_COLLECT = 'READY_TO_COLLECT',
  REJECTED = 'REJECTED',
  COLLECTED = 'COLLECTED',
  CANCELLED = 'CANCELLED',
}

const statuses = [
  marker('CLICK_AND_COLLECT.SCHEDULE.STATUS.UNCOLLECTED'),
  marker('CLICK_AND_COLLECT.SCHEDULE.STATUS.TO_ACCEPT'),
  marker('CLICK_AND_COLLECT.SCHEDULE.STATUS.TO_PREPARE'),
  marker('CLICK_AND_COLLECT.SCHEDULE.STATUS.IN_PREPARATION'),
  marker('CLICK_AND_COLLECT.SCHEDULE.STATUS.READY_TO_COLLECT'),
  marker('CLICK_AND_COLLECT.SCHEDULE.STATUS.REJECTED'),
  marker('CLICK_AND_COLLECT.SCHEDULE.STATUS.COLLECTED'),
  marker('CLICK_AND_COLLECT.SCHEDULE.STATUS.CANCELLED'),
];

const headers = [
  marker('CLICK_AND_COLLECT.ORDERS.HEADER.STATUS.UNCOLLECTED'),
  marker('CLICK_AND_COLLECT.ORDERS.HEADER.STATUS.TO_ACCEPT'),
  marker('CLICK_AND_COLLECT.ORDERS.HEADER.STATUS.TO_PREPARE'),
  marker('CLICK_AND_COLLECT.ORDERS.HEADER.STATUS.IN_PREPARATION'),
  marker('CLICK_AND_COLLECT.ORDERS.HEADER.STATUS.HISTORY'),
];

export interface Schedule {
  status: ScheduleStatus,
  count: number;
}

export interface GetOrdersCountResponse {
  schedules: Schedule[];
}
